<template>
    <div v-if="user_data.is_logged_in" >
        <v-navigation-drawer app  permanent expand-on-hover :mini-variant="! nav_draw_open"   floating  color="#272727a0"   >

                  <v-list nav dense style="background-color:#00000000">

                    <v-list-item class="px-0">
                      <v-list-item-avatar>
                          <illium-logo-svg></illium-logo-svg>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-list-item link  to="/">
                      <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title  > Home </v-list-item-title>
                      <v-list-item-subtitle> </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item link @click="user_dialog=true;">
                      <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title  > {{user_data.name}}  in {{selected_team.name}} </v-list-item-title>
                    </v-list-item>

                    <v-list-item link  to="/wallets">
                      <v-list-item-icon>
                        <v-icon>mdi-sack</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title  > Assets </v-list-item-title>
                      <v-list-item-subtitle> </v-list-item-subtitle>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-list-item link to="/intel">
                      <v-list-item-icon>
                        <v-icon>mdi-book-information-variant</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title  >Trends</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-list-item link to="/agents" >
                      <v-list-item-icon>
                        <v-icon>mdi-robot</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title >Automation</v-list-item-title>
                    </v-list-item>

                    <v-list-item link to="/swap" >
                      <v-list-item-icon>
                        <v-icon>mdi-swap-horizontal</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title >Swap</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-list-item link to="/pair-lists">
                      <v-list-item-icon>
                        <v-icon>mdi-minus-circle-multiple</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Token Lists </v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/trader-lists">
                      <v-list-item-icon>
                        <v-icon>mdi-wallet</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Trader Lists </v-list-item-title>
                    </v-list-item>
                    
                    <v-divider v-if="is_rodney" ></v-divider>
                    <v-list-item link to="/dev" v-if="is_rodney">
                      <v-list-item-icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Sys Maintenance </v-list-item-title>
                    </v-list-item>
                  </v-list>
        </v-navigation-drawer>


    <v-dialog v-model="user_dialog"  max-width="70vw" >
      <v-sheet rounded="sm">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="12">
              <h3>Select a team </h3>
              <v-select v-model="selected_team" :items="user_data.info.teams" @change="update_selected_team" label="Team" color="white" dense rounded solo hide-details >
                  <template v-slot:selection="{ item, index }">
                    {{item.name}}
                  </template>
                  <template v-slot:item="{ item, index }">
                    {{item.name}}
                  </template>
              </v-select>
              
            </v-col>
            <v-col cols="12" md="12">
              
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
      


    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import IlliumLogoSvg from '@/components/illium-logo-svg.vue'
export default {
    name: 'navigation-main',
    components:
        {
          IlliumLogoSvg
    },
    emits:[
    ],
    data(){return {
        nav_draw_open:false,
        mobile_mode : false,
        mobile_menu:false,
        nav_draw_open:false,
        selected_team:undefined,
        user_dialog:false,

        menu:[
            {text:'home'}
            ]
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.mobile_mode = this.is_mobile
    },
    methods:{
        ...mapActions(['person_update_settings']),
    sidebar_transitioned(e){
      this.nav_draw_open = ! this.nav_draw_open
    },
    update_selected_team(){
      console.log("Updating selected team");
      this.$store.commit('set_selected_team', this.selected_team.id)
      this.person_update_settings({qs:{},data:this.user_data.info.settings}).then(r=>r)
    }

    },
    computed:{
        ...mapState(['user_data','is_mobile']),
        is_rodney(){
          if (this.user_data == undefined) return false;
          if (this.user_data.info == undefined) return false;
          return this.user_data.info.id == 1
        }
    },
    watch:{
        user_data(nv, ov){
            if (this.user_data.info){ this.selected_team = this.user_data.info.selected_team }
        }
    },
}
</script>
<style scoped lang="scss">
.appbar{
  display:flex;
  width:100%;
  background-color: transparent;
  .barsection {
    flex:1;
  }
}

</style>