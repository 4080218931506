<template>
  <div>
    <v-app id="inspire">
      <navigation-main></navigation-main>
      <v-main>
        <div
          :class="{ 'app-content': user_data.is_logged_in }"
          v-if="is_loaded"
        >
          <div
            :class="is_mobile ? 'router-view-mobile' : 'router-view-desktop'"
          >
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
        </div>

        <div
          v-else
          class="splash-loading"
          :style="`background:  url(${db_url}static/img/loading.gif);background-size: 50% auto;  background-position: center center; background-repeat: no-repeat; opacity: 0.7; background-color:#101010;`"
        >
          <div class="mb-3" :style="``"></div>
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
// Begin Component Imports
// End Component Imports
import { mapState, mapActions } from "vuex";
import NavigationMain from "./components/navigation-main.vue";
export default {
  name: "App",
  components: {
    NavigationMain,
  },
  emits: [],
  data() {
    return {
      value: "recent",
      is_loaded: false,
      is_public: true,
    };
  },
  props: {
    //propName:{type:String,default:''},
    //data:{type:Object,default:Object},
    //list:{type:Array,default:Array},
  },
  mounted() {
    this.is_public = this.is_public_page();
  },
  methods: {
    ...mapActions([]),
    is_public_page() {
      const public_endpoints = ["/login", "/pair", "/lander"];
      for (let pe = 0; pe < public_endpoints.length; pe++) {
        if (window.location.pathname.startsWith(public_endpoints[pe])) {
          return true;
        }
      }
      return false;
    },
    login_shield() {
      if (!this.user_data) {
        return;
      }
      if (!this.user_data.is_logged_in) {
        if (this.is_public_page()) {
          return;
        }
        window.location = `/login?next=${window.location.origin}`;
      }
    },
  },
  computed: {
    ...mapState(["user_data", "db_url", "is_mobile"]),
  },
  watch: {
    user_data(nv, ov) {
      this.login_shield();
      this.is_loaded = true;
    },
  },
};
</script>
<style  lang="scss">

.amt-input{
    color:white;
    font-size: 2em;
}

.display-center-together{
  display:flex;
  justify-content: center;
}

.splash-loading {
  height: 100vh;
  width: 100vw;
  // background:linear-gradient(to right top, #6d327cc7, #485DA679, #00a1ba79, #00BF9879, #36C486);

  //  background:red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}

.router-view-desktop {
  width: calc(100vw - 60px);
}
.router-view-mobile {
  width: 100vw;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.roll-down-enter-active,
.roll-down-leave-active {
  transition: max-height 0.5s ease-in-out;
}
.roll-down-enter,
.roll-down-leave-to {
  max-height: 0;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: #13131300;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.app-content {
  display: flex;
}
.user-name {
  text-transform: capitalize;
}
.side-bar {
  overflow: auto;
}

.theme--dark.v-sheet,
.theme--dark.v-app-bar.v-toolbar.v-sheet {
  // background-color: #1e1e1ec9;
  border-radius: 1em;
  backdrop-filter: blur(10px);

  max-width: 100vw;
  background-color: #1e1e1e8c !important;
}

#inspire {
  //  background:linear-gradient(45deg,rgb(5, 5, 5), rgb(57, 85, 99))!important;
  //  background:linear-gradient(45deg,rgb(5, 5, 5), rgb(57, 85, 99))!important;
  background: linear-gradient(
    to right top,
    #3a1b42,
    #485da6,
    #00a1ba,
    #00bf98,
    #36c486
  );
  background: linear-gradient(
    to right top,
    #ce0bff,
    #485da6,
    #00a1ba,
    #00bf98,
    #36c486
  );
  // background: linear-gradient(21deg, #642343, #bca278);
  // background: linear-gradient(276deg, #619bd2, #2e2536);

  // background-image: linear-gradient(#8b9da9, #fff6e4);
  background-image: url(http://134.209.91.206/static/img/jungle-bg.jpg);
  // background-image: url(hhttps://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/ec2fa697-24a3-453e-98aa-9daa19ff5d78/da4syw7-bd4062e0-5e21-4cc8-95ab-fabd4dda96e4.jpg/v1/fill/w_1192,h_670,q_70,strp/swamp_by_mclelun_da4syw7-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvZWMyZmE2OTctMjRhMy00NTNlLTk4YWEtOWRhYTE5ZmY1ZDc4XC9kYTRzeXc3LWJkNDA2MmUwLTVlMjEtNGNjOC05NWFiLWZhYmQ0ZGRhOTZlNC5qcGciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.2zaKMdsAa1gx_fAsiKBm4UHd0lpAtnQesr_KMDDCKRk);
  background-size: cover;

  // filter: contrast(90%);
  // filter: brightness(10%);
  // filter: saturate(10%);
  box-shadow: inset 0 0 100px hsla(0, 0%, 0%, 0.3);
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  color: white;
  //  min-height:100vh;
}
h1,
h2,
h3,
h4,
h5 {
  text-align: center;
  text-transform: uppercase;
}
table {
  width: 100%;
  tr:first-child{
    th:first-child{
      border-top-left-radius: 1em;
    }
    th:last-child{
      border-top-right-radius: 1em;
      padding-right:3px;

    }
  }
}
tr {

}
tr:hover {
  background-color: rgba(255, 255, 255, 0.247);
}
td {
  background-color: rgba(255, 255, 255, 0.01);
  
}
th {
  background-color: rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  text-align: center;
  font-size: 0.87em;
}

a {
  color: hsl(199deg 32% 70%);
}
a:hover {
  color: hsl(199deg 42% 90%);
  text-decoration: none;
}
*:focus {
  outline: none;
}

pre {
  background: rgba(1, 37, 105, 0.445);
  color: rgb(255, 255, 255);
}
.mainsheet {
  background-color: #272727a0;
}

.loader-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
}

.v-menu__content {
  .v-sheet {
    border-radius: 0px;
  }
  .v-list {
    padding: 0x !important;
  }
  .v-list-item--highlighted {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0),
      rgba(56, 104, 158, 0.473),
      rgba(0, 0, 0, 0)
    );
  }
}

.v-navigation-drawer__content {
  .v-sheet {
    border-radius: 0px !important;
    // background: red!important;
  }
}
//  REMOVE BG FROM VTABS
.theme--dark.v-tabs-items,
.v-tabs-bar {
  background-color: #1e1e1e00 !important;
}

.theme--dark.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}
</style>


