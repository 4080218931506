import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function db_url_by_env(cenv){

  const testing_db = 'http://134.209.91.206/'
  const staging_db= '/'
  const production_db = '/'

  if (window.location.href.includes('localhost')){
    return testing_db
  }
  return staging_db
}

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function getCallerComponent() {
    try { throw new Error(); } 
    catch (e) {
      var callerInfo = ["Unable to get stack"] 
      try{

        const stack = e.stack.split('\n');
        // console.log("CALLER COMP", stack);
      
        callerInfo = stack.filter(trace => trace.includes('at VueComponent') || trace.includes('at Store'))
                                .filter(trace => !( trace.includes('Store.boundDispatch') || trace.includes('Store.dispatch') || trace.includes('VueComponent.mappedAction')))
                                .map(trace => trace.replaceAll('webpack-internal:///./node_modules/cache-loader/dist/cjs.js?!','')
                                .replaceAll('./node_modules/babel-loader/lib/index.js!','')
                                .replaceAll('./node_modules/cache-loader/dist/cjs.js?!','')
                                .replaceAll('./node_modules/vue-loader/lib/index.js?!','')
                                .replaceAll('webpack-internal:///./src/store/index.js','')
                                .replaceAll('&type=script&lang=js&','')
                                .replaceAll('at ','').trim() )
      } 
      catch{ }
      const time_of_call = Date.now()
      return {stack:callerInfo,'time_of_call':time_of_call};
  }
}

function display_error_body_new_tab(err, url){
  var newTab = window.open();
  err += `<style> 
  #requestinfo, #summary, #traceback, pre, th, td,li, h1 {
    background: #297ca3!important;
    color:white!important;
    
  
  }
  .commands{
    color:#ffffff7a!important;
  }

  td, th{
    border:1px solid white!important;
  }
  
  </style>`
  newTab.document.write(err);
  newTab.document.close();

}

function console_log_request(endpoint, stacked_data, ret_data){
  try{

    console.group(endpoint);
    console.log("=== Caller === ", stacked_data.stack);
    console.log("Return Data", ret_data);
    console.log((Date.now() - stacked_data.time_of_call)/1000," Seconds taken ");
    console.groupEnd();
  }catch{
    console.log("Could not log out some request info");
  }
}

function _get_db(state, endpoint) {
  return fetch(
    db_url_by_env(endpoint) + endpoint

  )
    .then((response) => {
      document.cookie = "";
      return response.json();
    })
    .then((json) => {
      console.log(endpoint, json);
      return json;
    })
    .catch(function (err) {
      return err.message;
    });
}

function _post_db(state, endpoint, data, jsonify = true) {
  // console.log("posting:", data);
  if (jsonify) {
    data = JSON.stringify(data);
  }
  return fetch(db_url_by_env(endpoint) + endpoint, {
    method: "post",
    //  credentials: "include",
    body: data,
  })
    .then((response) => {
      if ([500,404].includes(response.status)){
        response.text().then((r)=>{
            display_error_body_new_tab(r, endpoint)
        })
      }
      return response.json()
    
    })
    .then((json) => {
      console.log(endpoint, json);
      return json;
    })
    .catch((err) => console.log(`Post Request  Failed`, err, data));
}


function _get_db_qs(state, endpoint, qs) {

  qs = new URLSearchParams(qs).toString();
  endpoint =  endpoint + '?' + qs

  return fetch( db_url_by_env(endpoint) + endpoint )
    .then((response) => { 
      document.cookie = ""; 
      if ([500,404].includes(response.status)){
        response.text().then((r)=>{
            display_error_body_new_tab(r, endpoint)
        })
      }
    return response.json(); 
    })
    .then((json) => {
      console_log_request(endpoint,state.debug_context_rgm,json) 
      // console.log(endpoint, json);
      // console.log(state.debug_context_rgm) 
      return json; 
    })
    .catch(function (err) { 
      console.error("Frontend error parsing json", err)
      console.error(err)
      console.error(err.stack)
      return err.message; 
    });
}



function _post_db_qs(state, endpoint,  qs, data, jsonify = true) {
  qs = new URLSearchParams(qs).toString();
  endpoint =  endpoint + '?' + qs

  console.log("posting:", data);
  if (jsonify) {
    data = JSON.stringify(data);
  }
  return fetch(db_url_by_env(endpoint) + endpoint, {
    method: "post",
    //  credentials: "include",
    body: data,
  })
    .then((response) => {
      if ([500,404].includes(response.status)){
        response.text().then((r)=>{
            display_error_body_new_tab(r, endpoint)
        })
      }
      return response.json()
    
    })
    .then((json) => {
      // console.log(endpoint, json);
      // console.log(state.debug_context_rgm)
      console_log_request(endpoint,state.debug_context_rgm,json) 
      return json;
    })
    .catch((err) => console.log(`Post Request  Failed`, err, data));
}

function make_request(method, endpoint, qs, data, state){
    state.debug_context_rgm = getCallerComponent()
    if (method == 'GET') return _get_db_qs (state, endpoint, qs )
    if (method == 'POST' ){ return _post_db_qs(state,endpoint,qs, data) }
}




let custom_actions = {}
const store = new Vuex.Store({
  state: {
    db_url:db_url_by_env(),
    user_data: {is_logged_in:false, info:undefined, selected_team:undefined},
    active_wallet:undefined,
    investement_amt:0.3,
    time_intervals:{
      list:[ '1m', '5m','10m', '30m', '1H', '3H', '6H', '12H', '1D', '3D', '7D', '30D', '1Q'],
      seconds:{ '1m':60, '5m':5*60,'10m':10*60, '30m':30*60, '1H':60*60, '3H':60*60*3, '6H':60*60*6, '12H':60*60*12, '1D':60*60*24, '3D':60*60*24*3, '7D':60*60*24*7, '30D':60*60*24*30, '1Q': 60*60*24*90 }
    },
    
    
    is_mobile:isMobileDevice(),
    selected_team:undefined,
  },
  mutations: {
    set_user_data(state, user_data){ 
      state.user_data = user_data; 
      if(user_data.info.settings.selected.team){
        state.selected_team = state.user_data.info.teams.filter(x => x.id == user_data.info.settings.selected.team)[0] 
      }else{
        state.selected_team = user_data.info.teams[0]
        user_data.info.settings.selected.team = state.selected_team.id 
      }
    },
    set_active_wallet(state, value) { state.active_wallet = value; },
    set_investement_amt(state, value) { state.investement_amt = value; },
    set_selected_team(state, new_team_id) { 
      state.user_data.info.settings.selected.team = new_team_id;

      state.selected_team = state.user_data.info.teams.filter(x => x.id == new_team_id)[0]
      state.user_data.info.selected_team = state.selected_team; 
    },
    set_selected_pair_dynamic_list(state){

    },
    set_selected_pair_static_list(state){

    },
    set_selected_trader_dynamic_list(state){

    },
    set_selected_trader_static_list(state){

    },
  },
  actions:{
            get_user_info                         ({state} , qs   ) { return _get_db    (state, `sys/user_info`                                                                                                 )},
            model_method                          ({state} , qs   ) { return _get_db    (state, `sys/model/method?model=${qs.model}&method=${qs.method}&obj_id=${qs.obj_id}`                                    )},
            sys_mcount                            ({state} , qs   ) { return _get_db    (state, `sys/mcount`                                                                                                    )},
            login_usr_pw                        ({state} , qsdata ) {return make_request ("POST"  , `sys/login`          , qsdata.qs, qsdata.data, state ) },
            login_metamask                        ({state} , data ) { return _post_db   (state, `sys/login_metamask`, data                                                                                      )},
            load_latest_pairs                     ({state} , qs   ) { return _get_db    (state, `new/pairs/load?wk_year=${qs.wk_year}&day=${qs.day}`                                                            )},
            sym_trade                             ({state} , qs   ) { return _get_db    (state, `new/pairs/sym_trade?pair_id=${qs.pair_id}`                                                                     )},
            sync_with_defi_db                     ({state} , qs   ) { return _get_db    (state, `new/pairs/sync_load?n=${qs.n}`                                                                                 )},
            pairs_launched_cnt_month              ({state} , qs   ) { return _get_db_qs (state, `pairs/new/month/overview/cnt`, qs                                                                              )},
            pairs_launched_day                    ({state} , qs   ) { return _get_db_qs (state, `pairs/new/day/list`, qs                                                                                        )},
            docs_actions_all                      ({state} , qs   ) { return _get_db    (state, `docs/actions/all`                                                                                              )},
            get_strategies_list                   ({state} , qs   ) { return _get_db    (state, `strategy/all`                                                                                                  )},
            get_active_pairs_pricewise            ({state} , qs   ) { return _get_db_qs (state, `intel/active_pairs`, qs                                                                                        )},
            strats_crud_strat                     ({state} , data ) { return _post_db   (state, `strategy/crud/strategy`, data                                                                                  )},
            strats_crud_stage                     ({state} , data ) { return _post_db   (state, `strategy/crud/stage`, data                                                                                     )},
            strats_crud_action                    ({state} , data ) { return _post_db   (state, `strategy/crud/action`, data                                                                                    )},
            strats_crud_condition                 ({state} , data ) { return _post_db   (state, `strategy/crud/condition`, data                                                                                 )},
            intel_pairlaunch_name_wordusage       ({state} , qs   ) { return _get_db_qs (state, `intel/pairs/launched/names/top`, qs                                                                            )},
            intel_pairlaunch_binned_mentions      ({state} , qs   ) { return _get_db_qs (state, `intel/pairs/launched/names/freq`, qs                                                                           )},
            intel_telegram_get_pair_mentions      ({state} , qs   ) { return _get_db_qs (state, `intel/pairs/telegram/mentions/agg`, qs                                                                         )},
            backtest_pair                         ({state} , qs   ) { return _get_db_qs (state, `strategies/backtesting/backtest_pair`, qs                                                                      )},
            get_pair_by_address_page              ({state} , qs   ) { return _get_db_qs (state, `api/objects/page/pair_by_address` , qs                                                                         )},
            unit_pair_get                         ({state} , qs   ) { return _get_db_qs (state, `unit/pairs/get` , qs                                                                                           )},
            request_simulated_trade               ({state} , qs   ) { return _get_db_qs (state, `api/user/request_simulated_trade` , qs                                                                         )},
            unit_strategy_implementation_pair_get ({state} , qs   ) { return _get_db_qs (state, `unit/strategy_implementation_pair/get` , qs                                                                    )},
            load_latest_pairs_n                   ({state} , qs   ) { return _get_db_qs (state, `api/user/pairs/latest/load_latest_pairs_n` , qs                                                                )},
            pair_list_views_get_view              ({state} , qs   ) { return _get_db_qs (state, `api/user/views/pair_list_views_get_view` , qs                                                                  )},
            pair_list_view_custom                 ({state} , data ) { return _post_db   (state, `api/user/pair_list_view_custom` , data                                                                         )},
            pair_list_views_set_view              ({state} , data ) { return _post_db   (state, `api/user/views/pair_list_views_set_view?view_name=${data.qs.view_name}&view_on=${data.qs.view_on}` , data.data )},
            unit_metric_test                      ({state} , data ) { return _post_db   (state, `test/metric/test` , data                                                                                       )},
            
            intel_trader_traders_agg                   ({state} , qsdata ) {return make_request ("GET"  ,'intel/pairs/trades/traders/agg'                      , qsdata.qs, qsdata.data, state ) },
            intel_trade_pairs_agg                      ({state} , qsdata ) {return make_request ("GET"  ,'intel/pairs/trades/pairs/agg'                        , qsdata.qs, qsdata.data, state ) },
            test_unit_metrics                          ({state} , qsdata ) {return make_request ("GET"  ,'metrics-tester/all'                                    , qsdata.qs, qsdata.data, state ) },
            pair_list_view_custom_data                 ({state} , qsdata ) {return make_request ("POST" ,'api/user/pair_list_view_custom'                      , qsdata.qs, qsdata.data, state );},
            static_pair_list_actions                   ({state} , qsdata ) {return make_request ("POST" ,'api/user/portfolios/pair/actions'                    , qsdata.qs, qsdata.data, state );},
            static_trader_list_actions                 ({state} , qsdata ) {return make_request ("POST" ,'api/user/portfolios/trader/actions'                  , qsdata.qs, qsdata.data, state );},
            person_update_settings                     ({state} , qsdata ) {return make_request ("POST" ,'api/user/update_settings'                            , qsdata.qs, qsdata.data, state );},
            strategy_implementation_action             ({state} , qsdata ) {return make_request ("POST" ,'api/user/strategy_implementation/action'             , qsdata.qs, qsdata.data, state );},
            pair_list_dynamic_actions                  ({state} , qsdata ) {return make_request ("POST" ,'api/user/pair_list_dynamic/actions'                  , qsdata.qs, qsdata.data, state );},
            backtest_implementation_from               ({state} , qsdata ) {return make_request ("GET"  ,'agents/implementation/backtest'                      , qsdata.qs, qsdata.data, state );},
            get_implementation_binned_pairs_stage      ({state} , qsdata ) {return make_request ("GET"  ,'agents/implementation/get_status'                    , qsdata.qs, qsdata.data, state );},
            maint_get_latest_update_times              ({state} , qsdata ) {return make_request ("GET"  ,'api/maint/latest_update_times'                       , qsdata.qs, qsdata.data, state );},
            trader_trade_metrics                       ({state} , qsdata ) {return make_request ("GET"  ,'intel/traders/metrics'                               , qsdata.qs, qsdata.data, state );},
            trader_union_matrix                        ({state} , qsdata ) {return make_request ("GET"  ,'intel/pairs/trader_union_matrix'                     , qsdata.qs, qsdata.data, state );},
            traders_list_actions                       ({state} , qsdata ) {return make_request ("POST" ,'intel/traders/actions'                               , qsdata.qs, qsdata.data, state );},
            toggle_implementation_on_off               ({state} , qsdata ) {return make_request ("GET"  ,'agents/implementation/toggle_on_off'                 , qsdata.qs, qsdata.data, state );},
            gas_estimation_schedule                    ({state} , qsdata ) {return make_request ("GET"  ,'oracle/gas/estimation/schedule'                      , qsdata.qs, qsdata.data, state );},
            gas_price_historical                       ({state} , qsdata ) {return make_request ("GET"  ,'oracle/gas/historical'                               , qsdata.qs, qsdata.data, state );},
            gas_estimation_histogram                   ({state} , qsdata ) {return make_request ("GET"  ,'oracle/gas/estimation'                               , qsdata.qs, qsdata.data, state );},
            metric_latest_data_binned_time             ({state} , qsdata ) {return make_request ("POST" ,'api/user/general/metric_latest_data_binned_time'     , qsdata.qs, qsdata.data, state );},
            trader_intel_calculate_similar_traders     ({state} , qsdata ) {return make_request ("GET"  ,'intel/trader/similar_traders/calculate'              , qsdata.qs, qsdata.data, state );},
            trader_intel_get_similar_traders           ({state} , qsdata ) {return make_request ("GET"  ,'intel/trader/similar_traders/get'                    , qsdata.qs, qsdata.data, state );},
            trader_intel_top_traders                   ({state} , qsdata ) {return make_request ("GET"  ,'intel/traders/top'                                   , qsdata.qs, qsdata.data, state );},
            trader_search                              ({state} , qsdata ) {return make_request ("GET"  ,'intel/trader/search'                                 , qsdata.qs, qsdata.data, state );},
            defi_total_state_cashflow_graphs           ({state} , qsdata ) {return make_request ("GET"  ,'intel/defi/time_series/cashflows'                    , qsdata.qs, qsdata.data, state );},
            get_implementation_holdings                ({state} , qsdata ) {return make_request ("GET"  ,'agents/implementation/holdings'                      , qsdata.qs, qsdata.data, state );},
            get_lists_for_team                         ({state} , qsdata ) {return make_request ("GET"  ,'api/user/list/team'                                  , qsdata.qs, qsdata.data, state );},
            token_list_actions                         ({state} , qsdata ) {return make_request ("POST" ,'api/user/list/tokens/token_list_actions'             , qsdata.qs, qsdata.data, state );},
            get_all_metrics                            ({state} , qsdata ) {return make_request ("GET"  ,'metrics/get_all_metrics'                             , qsdata.qs, qsdata.data, state );},
            pair_metrics_scatter_data                  ({state} , qsdata ) {return make_request ("POST" ,'metrics/pair/get_metrics'                            , qsdata.qs, qsdata.data, state );},
            action_options_operations                  ({state} , qsdata ) {return make_request ("POST" ,'api/maint/action_options_ops'                        , qsdata.qs, qsdata.data, state );},
            person_user_actions                        ({state} , qsdata ) {return make_request ("POST" ,'api/maint/person_user_actions'                       , qsdata.qs, qsdata.data, state );},
            single_metric_calculator                   ({state} , qsdata ) {return make_request ("POST" ,'api/user/single_metric_calculator'                   , qsdata.qs, qsdata.data, state );},
            pair_input_stream_test                     ({state} , qsdata ) {return make_request ("POST" ,'dynamic_list/test_get/pair_input_stream_test'        , qsdata.qs, qsdata.data, state );},
            list_all_input_streams                     ({state} , qsdata ) {return make_request ("GET"  ,'api/user/pair_list_dynamic/input_streams/list'       , qsdata.qs, qsdata.data, state );},
            get_trader_score                           ({state} , qsdata ) {return make_request ("POST" ,'intel/trader/score/get'                              , qsdata.qs, qsdata.data, state );},
            trader_page_get_views                      ({state} , qsdata ) {return make_request ("GET"  ,'api/user/view/traders/views'                         , qsdata.qs, qsdata.data, state );},
            force_recalc_trades                        ({state} , qsdata ) {return make_request ("GET"  ,'api/correct/trade_calcs'                             , qsdata.qs, qsdata.data, state );},
            get_latest_blockchain_addrs_count_activity ({state} , qsdata ) {return make_request ("GET"  ,'intel/blockchain/addrs_count/activity/latest'        , qsdata.qs, qsdata.data, state );},
            traders_calc_list_counts                   ({state} , qsdata ) {return make_request ("POST" ,'api/user/view/traders/counts'                        , qsdata.qs, qsdata.data, state );},
            pair_list_trades                           ({state} , qsdata ) {return make_request ("GET"  ,'api/user/view/pair/trades'                           , qsdata.qs, qsdata.data, state );},
            overall_activity_data                      ({state} , qsdata ) {return make_request ("GET"  ,'intel/overall/activity_data'                         , qsdata.qs, qsdata.data, state );},
            trader_ensure_complete_trades              ({state} , qsdata ) {return make_request ("POST" ,'api/maint/traders/fix_prices_ensure_complete_trades' , qsdata.qs, qsdata.data, state );},
            trader_calculate_get_standard_scores       ({state} , qsdata ) {return make_request ("POST" ,'api/maint/traders/calculate_get_standard_scores'     , qsdata.qs, qsdata.data, state );},
            trader_metrics_counts_token_test_pca       ({state} , qsdata ) {return make_request ("GET"  ,'intel/pairs/pair/pca'                                , qsdata.qs, qsdata.data, state );},

            swap_app_wallet_balances                     ({state} , qsdata ) { return make_request ("GET"  ,'api/user/swap_app/wallet/balances'                                , qsdata.qs, qsdata.data, state );},
            swap_app_pair_price                          ({state} , qsdata ) { return make_request ("GET"  ,'api/user/swap_app/pair/price'                                     , qsdata.qs, qsdata.data, state );},
            swap_app_pair_sim_tx                         ({state} , qsdata ) { return make_request ("GET"  ,'api/user/swap_app/pair/sim/tx'                                    , qsdata.qs, qsdata.data, state );},
            swap_app_search_pair                         ({state} , qsdata ) { return make_request ("GET"  ,'api/user/swap_app/pair/search'                                    , qsdata.qs, qsdata.data, state );},
            swap_do_tx                                   ({state} , qsdata ) { return make_request ("POST" ,'api/user/swap_app/do_tx'                                          , qsdata.qs, qsdata.data, state );},
            get_bc_tx_details                            ({state} , qsdata ) { return make_request ("GET"  ,'api/user/agent/tx/details'                                        , qsdata.qs, qsdata.data, state );},
            pair_view_price_ohlc                         ({state} , qsdata ) { return make_request ("GET"  ,'api/user/pair/ohlc'                                               , qsdata.qs, qsdata.data, state );},
            manually_import_pair_to_implementation       ({state} , qsdata ) { return make_request ("GET"  ,'api/user/agent/manually_import_pair_to_implementation'            , qsdata.qs, qsdata.data, state );},
            schedule_person_batch_job                    ({state} , qsdata ) { return make_request ("POST" ,'api/user/schedule_batch_job'                                      , qsdata.qs, qsdata.data, state );},
            signup_request                               ({state} , qsdata ) { return make_request ('POST' ,'/system/user/request_access'                                      , qsdata.qs, qsdata.data, state );},
            get_contract_source_code_and_meta            ({state} , qsdata ) { return make_request ("GET"  ,'api/user/contract/source'                                         , qsdata.qs, qsdata.data, state );},
            tags_by_trader                               ({state} , qsdata ) { return make_request ("POST" ,'api/user/trader/tags/tags_by_trader'                              , qsdata.qs, qsdata.data, state );},
            pair_get_section_traders_trades_explore_info ({state} , qsdata ) { return make_request ("GET"  ,'api/user/pair/slice/pair_get_section_traders_trades_explore_info' , qsdata.qs, qsdata.data, state );},
            team_wallets_list_wallets({state},qsdata){return make_request("GET",'api/user/wallets/team_get_list',qsdata.qs,qsdata.data,state);},
            
            wallet_bag_init_bag_import({state},qsdata){return make_request("POST",'api/user/wallets/wallet_bag_init_bag_import',qsdata.qs,qsdata.data,state);},
            get_wallet_bags({state},qsdata){return make_request("GET",'api/user/wallets/get_bags_for_wallet',qsdata.qs,qsdata.data,state);},
            get_bag_logs({state},qsdata){return make_request("GET",'api/user/wallets/bag/logs',qsdata.qs,qsdata.data,state);},
            
            ...custom_actions                    ,
      },
  modules: {
  }
})




// store.actions.get_user_info()
console.log(store._actions.get_user_info[0]().then(r=>{ store._mutations.set_user_data[0](r.data) }).catch(r=>{}));

export default store