<template>
    <div>
                                <svg width="174" height="25" viewBox="0 0 174 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="51" y="47" width="123" height="41" rx="2" fill="#AE5650"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M149.887 0.977137C149.526 0.371253 148.873 0 148.168 0H6C4.89543 0 4 0.895431 4 2V39C4 40.1046 4.89543 41 6 41H170.189C171.74 41 172.701 39.3103 171.908 37.9771L149.887 0.977137Z" fill="#D9D9D9"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M170.408 94C171.975 94 172.933 95.7211 172.108 97.0535L149.18 134.053C148.816 134.642 148.172 135 147.48 135H6C4.89543 135 4 134.105 4 133V96C4 94.8954 4.89543 94 6 94H170.408Z" fill="#D9D9D9"/>
                                  <g filter="url(#filter0_d_201_19)">
                                  <rect x="4" y="47" width="41" height="41" rx="2" fill="#27497D"/>
                                  </g>
                                  <defs>
                                  <filter id="filter0_d_201_19" x="0" y="43" width="57" height="57" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                  <feMorphology radius="4" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_201_19"/>
                                  <feOffset dx="4" dy="4"/>
                                  <feGaussianBlur stdDeviation="2"/>
                                  <feComposite in2="hardAlpha" operator="out"/>
                                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_201_19"/>
                                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_201_19" result="shape"/>
                                  </filter>
                                  </defs>
                                </svg>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'illium-logo-svg',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>