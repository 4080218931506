import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


Vue.config.productionTip = false

Vue.config.warnHandler = function (msg, vm, trace) {
  console.error(`RGM Custom warning message: ${msg}`);
  var comp = vm
  while(comp.$parent != undefined){
    if(comp.$vnode != undefined){
      const endingsToCheck = ['-App',  '-v-app','-v-main','-v-sheet','-v-tab-item','-v-tabs-items','-v-tabs' ];
      const endsWithAny = endingsToCheck.some(ending => comp.$vnode.tag.endsWith(ending));
      if (!endsWithAny) {
        console.warn("rgm debug stack",comp.$vnode.tag, comp);
      }
    }
    comp = comp.$parent
  }

};

new Vue({
  router, store, vuetify,
  render: h => h(App)
}).$mount('#app')
