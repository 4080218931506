import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        dark: true ,
        themes:{

            dark:{
                primary: '#ffffff', // Your desired primary color
            },
            
        }
    },
});


// export default new Vuetify({
//     theme: {
//       themes: {
//         light: {
//           primary: '#ff5733', // Change primary color
//           secondary: '#007BFF', // Change secondary color
//           // Add or change more theme variables here
//         },
//       },
//     },
//   });