import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/'                   , name: 'Home'             , component: () => import('../components/view-dashboard-home.vue'            ) },
  { path: '/intel'              , name: 'Intel'            , component: () => import('../components/intel-view.vue'                     ) },
  { path: '/agents'             , name: 'Agents'           , component: () => import('../components/agents/view-agents.vue'             ) },
  { path: '/swap'               , name: 'Swap'             , component: () => import('../components/view-swap.vue'                      ) },
  { path: '/dev'                , name: 'Dev'              , component: () => import('../components/view-dev.vue'                       ) },
  { path: '/wallets'            , name: 'wallet-bags'      , component: () => import('../components/wallet_bags/view-wallet-bags'       ) },
  { path: '/pair-lists'         , name: 'Strategy'         , component: () => import('../components/view-my-lists.vue'                  ) },
  { path: '/trader-lists'       , name: 'TraderPortfolios' , component: () => import('../components/trader-portfolios-filters-view.vue' ) },
  { path: '/team'               , name: 'Team'             , component: () => import('../components/view-team.vue'                      ) },
  { path: '/new-tokens'         , name: 'HomeNewTokens'    , component: () => import('../components/new-tokens-view.vue'                ) },
  { path: '/pair/:pair_address' , name: 'Pair'             , component: () => import('../components/view-pair.vue'                      ) },
  { path: '/trader/:trader_id'  , name: 'Trader'           , component: () => import('../components/view-trader.vue'                    ) },
  { path: '/login'              , name: 'HomeLogin'        , component: () => import('../components/view-login'                      ) },
  { path: '/about-explain'      , name: 'HomeExplain'      , component: () => import('../components/view-about-explain'                 ) },
  
]

const router = new VueRouter({ mode: 'history', base: process.env.BASE_URL, routes })

export default router
